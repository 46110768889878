import React, { useState, useEffect } from "react";
import { service } from "../../network/Home/service";
import "react-multi-carousel/lib/styles.css";
import {
  convertTimeToLocal,
  deviceDetect,
  playerController,
  ssaiAdParam,
} from "../../Utils/utils";
import LiveSchedule from "./LiveSchedule";
var liveThumbnailUrl = "https://gizmeon.s.llnwi.net/vod/thumbnails/images/";
var details = [];
var pause = false;
const handleScroll = () => {
  let playerId = "singleVideo";
  if (deviceDetect() === true) {
    playerController(600, playerId);
  } else {
    playerController(150, playerId);
  }
};

const LiveContainer = () => {
  const [channels, setChannels] = useState([]);
  const [logo, setLogo] = useState("");
  const [videoPlayer, setVideoPlayer] = useState();
  const [livePlayerId, setLivePlayerId] = useState();
  const [liveLink, setLiveLink] = useState();
  const [isVideoPause, setIsVideoPause] = useState(true);
  let playerId = "singleVideo";
  useEffect(() => {
    service.getLiveChannels().then((response) => {
      if (response.data) {
        setLogo(response.data[0].logo);
        const details = response.data[0];
        setChannels(details.now_playing);

        if (details.live_link) {
          setLiveLink(details.live_link);
          let videoElem = "live_video" + new Date().valueOf();
          setLivePlayerId(videoElem);
          setVideoPlayer(
            ssaiAdParam(response.data[0]).then((params) => {
              window.playLivePlayer(videoElem, response.data[0], params);
            })

            // <ReactHlsPlayer
            //     id='singleVideo'
            //     url={response.data[0].live_link}
            //     autoplay={true}
            //     controls={true}
            //     width={'100%'}
            //     height={'100%'}
            //     onPlayerReady={onPlayerReady}
            //     onReady={onPlayerReady}
            //     onPlay={onVideoPlay}
            //     onPlaying={onPlayingFunction}
            //     onPause={onVideoPause}
            //     onEnded={onVideoEnd}
            // />
          );

          // setInterval(() => {
          //     service.getLiveChannels().then(response => {
          //         setLogo(response.data[0].logo);
          //         setChannels(response.data[0]);
          //     })
          // }, 60 * 1000);
        }
      }
    });
    // window.addEventListener('scroll', handleScroll)
  }, []);
  // const onPlayingFunction = () => {
  //     setInterval(() => {
  //         if (pause === false) {
  //             pause = false
  //         }
  //     }, 5000);
  // }
  // const onPlayerReady = () => {
  //     let event = 'POP02';
  //     // service.onVideoPlayFunction(details, event).then(response => {
  //     // })
  // }
  // const onVideoPlay = () => {
  //     let event = 'POP03';
  //     // service.onVideoPlayFunction(details, event).then(response => {
  //     // })
  // }
  // const onVideoPause = () => {
  //     let event = 'POP04';
  //     setIsVideoPause(false);
  //     pause = true
  //     // service.onVideoPlayFunction(details, event).then(response => {
  //     // })
  // }
  // const onVideoEnd = () => {
  //     let event = 'POP05';
  //     // service.onVideoPlayFunction(details, event).then(response => {
  //     // })
  // }
  return (
    <div className="live-container-page">
      <div className="entireBanner" id="live">
        <div className="hpLiveBanner">
          <div className="liveVideoWrapper">
            {videoPlayer && (
              <video
                id={livePlayerId}
                className="video-js vjs-default-skin livePlayer"
                controls
                preload="auto"
                autoPlay
              >
                <source src={liveLink} type="application/x-mpegURL" />
              </video>
            )}

            <div className="hpWrapperVideo" style={{ height: "88px" }}>
              <section className="movieTextWrapper vpRelatedMargin">
                <div className="vpRelatedImage-logo">
                  {logo && (
                    <img
                      alt={channels.video_title}
                      src={liveThumbnailUrl + logo}
                      width="100%"
                    />
                  )}
                  <div className="liveTvBlackOverlay"></div>
                  <div className="liveTvPlay"></div>
                </div>
                <div className="movieTextFlex">
                  <div className="movieCatYear">
                    <div>
                      <div className="movieCategory mcMargin">
                        <div>
                          {channels.start_time &&
                            convertTimeToLocal(channels.start_time)}
                          {channels.start_time && "-"}{" "}
                          {channels.end_time &&
                            convertTimeToLocal(channels.end_time)}
                        </div>
                      </div>
                    </div>
                  </div>
                  {channels.video_title && (
                    <h3>
                      <div className="linkButton movieTextHeading">
                        {channels.video_title}
                      </div>
                    </h3>
                  )}
                </div>
              </section>
            </div>
          </div>
          <div className="overlayTiles"></div>
        </div>
      </div>
      <div className="live-schedule">
        <LiveSchedule />
      </div>
    </div>
  );
};

export default LiveContainer;

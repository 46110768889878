import React, { useState, useEffect } from "react";
import FacebookLogin from "react-facebook-login";
import { service } from "../../network/service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory, useLocation, Redirect } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useSelector, useDispatch } from "react-redux";
import { deviceDetect } from "../../Utils/utils";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import $ from "jquery";
const clientId =
  "185522177985-i44ojji8qffqrj31lj7ftqp5h4d5a16k.apps.googleusercontent.com";
const SignIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [otherValues, setotherValues] = useState({
    firstname: "",
    email: "",
    password: "",
    userRegisterId: "",
    verification_code: "",
    msgError: "",
    msgErrorVerify: "",
    msgSuccess: "",
    forgot_email: "",
    userLoggedId: "",
    msgErrorForgot: "",
    msgSuccessLogin: "",
    msgErrorLogin: "",
    msgSuccessVerify: "",
    msgErrorVerify: "",
    msgSuccessForgot: "",
  });

  const [facebookEmail, setFacebookEmail] = useState("");

  const [flag, setFlag] = useState({
    isSuccessVerifyMsg: false,
    isErrorVerifyMsg: false,
    isSuccessForgotMsg: false,
    isErrorLoginMsg: false,
    isErrorForgotMsg: false,
    isLogin: true,
    isVerify: false,
    isForgot: false,
    isSuccessLoginMsg: false,
    isSuccessLogoutMsg: false,
    isErrorLogoutMsg: false,
    isErrorLogout: false,
    isGetIP: true,
    isEmailExistMsg: false,
    isEmailSelected: false,
    isForgotEmailSelected: false,
    isPasswordSelected: false,
    isVerifyEmailSelected: false,
  });

  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "Email",
    password: "Password",
  });
  const [valuesVerify, setValuesVerify] = useState({
    verification_code: "",
  });
  const [errorsVerify, setErrorsVerify] = useState({
    verification_code: "Verification Code",
  });
  const [valuesForgot, setValuesForgot] = useState({
    forgot_email: "",
  });
  const [errorsForgot, setErrorsForgot] = useState({
    forgot_email: "Email",
  });

  let FBData = null;
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [eye1, setEye1] = useState(<FontAwesomeIcon icon={faEye} />);
  const [isEye1, setIsEye1] = useState(false);
  const togglePasswordVisiblity1 = () => {
    setPasswordShown1(passwordShown1 ? false : true);
    setEye1(
      passwordShown1 ? (
        <FontAwesomeIcon icon={faEye} />
      ) : (
        <FontAwesomeIcon icon={faEyeSlash} />
      )
    );
  };
  const [showloginButton, setShowloginButton] = useState(true);
  const [showlogoutButton, setShowlogoutButton] = useState(false);
  const onLoginSuccess = (res) => {
    console.log("Login Success:", res.profileObj);
    console.log("success", res.googleId);
    service.googleLogin(res.profileObj).then((response) => {
      console.log("response123", response);
      if (response.status == 200) {
        console.log("response", response);
        service.setCookie("userId", response.data[0].user_id, 30);
        localStorage.setItem("userId", response.data[0].user_id);

        let loginGoogledata = response.data[0];
        service.userSubscription(response.data[0].user_id).then((response) => {
          setotherValues({
            ...otherValues,
            userLoggedId: loginGoogledata.user_id,
          });
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("userName", loginGoogledata.first_name);
          service.setCookie("userId", loginGoogledata.user_id, 30);
          localStorage.setItem("userId", loginGoogledata.user_id);
          service.setCookie("userEmail", loginGoogledata.user_email, 30);
          service.setCookie("isLoggedIn", "true", 30);

          var user_sub = response.data;
          if (user_sub.length > 0) {
            setotherValues({
              ...otherValues,
              msgSuccessLogin: "You are successfully logged in. ",
            });
            setFlag({ ...flag, isSuccessLoginMsg: true });
            setTimeout(function () {
              setFlag({ ...flag, isSuccessLoginMsg: false });
            }, 1000);
            dispatch({ type: "LOGIN", payload: true });
            if (sessionStorage.getItem("tvActivateFlag") == "true") {
              history.push("/tv");
            } else {
              if (getPrevUrl() == "live.gizmott.com") {
                history.push("/home");
              } else {
                history.goBack();
              }

              // window.location.href = "/home";
              //  history.push("/home")
            }
          } else {
            setotherValues({
              ...otherValues,
              msgSuccessLogin: "You are successfully logged in.",
            });
            setFlag({ ...flag, isSuccessLoginMsg: true });
            setTimeout(function () {
              setFlag({ ...flag, isSuccessLoginMsg: false });
            }, 5000);
            dispatch({ type: "LOGIN", payload: true });
            if (sessionStorage.getItem("tvActivateFlag") == "true") {
              history.push("/tv");
            } else {
              if (getPrevUrl() == "live.gizmott.com") {
                history.push("/home");
              } else {
                history.goBack();
              }
              // window.location.href = "/home";
              // history.push("/home")
            }
          }
        });
      } else if (response.status == 202) {
        setotherValues({
          ...otherValues,
          msgErrorLogin: "Please enter a valid user email and password ",
        });
        setFlag({ ...flag, isErrorLoginMsg: true });
        setTimeout(function () {
          setFlag({ ...flag, isErrorLoginMsg: false });
        }, 5000);
      } else if (response.status == 203) {
        // history.push({
        //   pathname: "/register",
        //   state: { facebookData: FBData },
        // });
      } else if (response.status == 201) {
        setotherValues({
          ...otherValues,
          userLoggedId: response.data[0].user_id,
          msgSuccessVerify:
            "OTP sent to your Email (Note:  If you do not find the email in your inbox, please check your spam filter or bulk email folder)",
        });
        setFlag({
          ...flag,
          isLogin: false,
          isVerify: true,
          isSuccessVerifyMsg: true,
        });

        setTimeout(function () {
          setFlag({ ...flag, isSuccessVerifyMsg: false });
        }, 5000);
      } else if (response.status == 204) {
        confirmAlert({
          closeOnEscape: false,
          closeOnClickOutside: false,
          message: "Do you want to link your Google account?",
          buttons: [
            {
              label: "Yes",
              onClick: () => onGoogleLink(res.profileObj),
            },
            {
              label: "No",
              onClick: () => onGoogleNoLink(),
            },
          ],
        });
      }
    });
    // let googleId = localStorage.setItem("googleId",res.profileObj);
    setShowloginButton(false);
    setShowlogoutButton(true);
  };

  const onLoginFailure = (res) => {
    console.log("Login Failed:", res);
  };

  const onSignoutSuccess = () => {
    alert("You have been logged out successfully");
    console.clear();
    setShowloginButton(true);
    setShowlogoutButton(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("previous url", getPrevUrl());

    let isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn === "true") {
      history.push({
        pathname: "/home",
      });
    }
    var siginElem = document.getElementById("signInLink");
    if (siginElem) siginElem.style.display = "none";
    if (flag.isGetIP) {
      fetch("https://giz.poppo.tv/service/ipinfo")
        .then((res) => res.json())
        .then((json) => localStorage.setItem("ipaddress", json.query));

      setFlag({ ...flag, isGetIP: false });
    }
  }, []);

  function getPrevUrl() {
    var preUrl = document.referrer.split("/");
    if (preUrl && preUrl.length > 3) {
      return preUrl[2];
    } else {
      return null;
    }
  }

  const validateEmail = (email) => {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      return true;
    }
    return false;
  };
  const validatePassword = (password) => {
    if (/^[a-zA-Z0-9@#$%&*^!.,+\-:;_\'\"?]{6,30}$/.test(password)) {
      return true;
    }
    return false;
  };
  const validateVerify = (verification_code) => {
    if (/^\d*$/.test(verification_code.trim())) {
      return true;
    }
    return false;
  };
  const onChangeHandlerVerify = (e) => {
    const { name, value } = e.target;

    setValuesVerify({
      ...valuesVerify,
      [name]: value,
    });
  };
  const onChangeHandlerForgot = (e) => {
    const { name, value } = e.target;

    setValuesForgot({
      ...valuesForgot,
      [name]: value,
    });
  };
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (name == "password") {
      if (value.length > 0) {
        setIsEye1(true);
      } else {
        setIsEye1(false);
      }
    }
  };
  const validationForgot = () => {
    let errorsForgot = {};
    let formIsValid = true;
    if (valuesForgot.forgot_email.trim()) {
      var errorMsg = validateEmail(valuesForgot.forgot_email);
      if (errorMsg === true) {
        errorsForgot.forgot_email = "Email";

        setotherValues({ ...otherValues, forgot_email: "" });
      } else {
        formIsValid = false;

        setotherValues({ ...otherValues, forgot_email: " Input--errored" });
        errorsForgot.forgot_email = "Invalid email Address";
      }
    } else {
      formIsValid = false;
      errorsForgot.forgot_email = "Required Email Field";

      setotherValues({ ...otherValues, forgot_email: " Input--errored" });
    }
    setErrorsForgot(errorsForgot);
    return formIsValid;
  };
  const validationVerify = () => {
    let errorsVerify = {};
    let formIsValid = true;
    if (valuesVerify.verification_code.trim()) {
      var errorMsg = validateVerify(valuesVerify.verification_code);
      if (errorMsg === true) {
        errorsVerify.verification_code = "Verification Code";

        setotherValues({ ...otherValues, verification_code: "" });
      } else {
        formIsValid = false;

        setotherValues({
          ...otherValues,
          verification_code: " Input--errored",
        });
        errorsVerify.verification_code = "OTP contain numeric only";
      }
    } else {
      formIsValid = false;
      errorsVerify.verification_code = "Required Verification Field";

      setotherValues({ ...otherValues, verification_code: " Input--errored" });
    }
    setErrorsVerify(errorsVerify);
    return formIsValid;
  };
  const validation = () => {
    let errors = {};
    let formIsValid = true;

    if (values.email.trim()) {
      var errorMsg = validateEmail(values.email);
      if (errorMsg === true) {
        errors.email = "Email";

        setotherValues({ ...otherValues, email: "" });
      } else {
        formIsValid = false;
        setotherValues({ ...otherValues, email: "Input--errored" });

        errors.email = "Invalid email Address";
      }
    } else {
      formIsValid = false;
      errors.email = "Required Email Field";
      setotherValues({ ...otherValues, email: "Input--errored" });
    }
    if (values.password.trim()) {
    } else {
      formIsValid = false;

      setotherValues({ ...otherValues, password: "Input--errored" });
      errors.password = "Required Password Field";
    }
    setErrors(errors);
    return formIsValid;
  };
  const responseFacebook = (response) => {
    FBData = response;
console.log("responsefacebook", response);
    setFacebookEmail(FBData.email);

    if (response.id != undefined && response.id != null && response.id != "") 
    service
      .facebookLogin(
        response.id,
        response.email,
        response.first_name,
        response.last_name
      )
      .then((response) => {
        console.log("inner response",response);
        if (response.status == 200) {
          let loginFBData = response.data.data[0];
          service.setCookie("userId", loginFBData.user_id, 30);
          localStorage.setItem("userId", loginFBData.user_id);

          
          service
            .userSubscription(loginFBData.user_id)
            .then((response) => {
              setotherValues({
                ...otherValues,
                userLoggedId: loginFBData.user_id,
              });
              localStorage.setItem("isLoggedIn", true);
              localStorage.setItem("userName", loginFBData.first_name);
              service.setCookie("userId", loginFBData.user_id, 30);
              localStorage.setItem("userId", loginFBData.user_id);
              service.setCookie("userEmail", loginFBData.user_email, 30);
              service.setCookie("isLoggedIn", "true", 30);

              var user_sub = response.data;
              if (user_sub.length > 0) {
                setotherValues({
                  ...otherValues,
                  msgSuccessLogin: "You are successfully logged in. ",
                });
                setFlag({ ...flag, isSuccessLoginMsg: true });
                setTimeout(function () {
                  setFlag({ ...flag, isSuccessLoginMsg: false });
                }, 1000);
                dispatch({ type: "LOGIN", payload: true });
                if (sessionStorage.getItem("tvActivateFlag") == "true") {
                  history.push("/tv");
                } else {
                  if (getPrevUrl() == "live.gizmott.com") {
                    history.push("/home");
                  } else {
                    history.goBack();
                  }

                  // window.location.href = "/home";
                  //  history.push("/home")
                }
              } else {
                setotherValues({
                  ...otherValues,
                  msgSuccessLogin: "You are successfully logged in.",
                });
                setFlag({ ...flag, isSuccessLoginMsg: true });
                setTimeout(function () {
                  setFlag({ ...flag, isSuccessLoginMsg: false });
                }, 5000);
                dispatch({ type: "LOGIN", payload: true });
                if (sessionStorage.getItem("tvActivateFlag") == "true") {
                  history.push("/tv");
                } else {
                  if (getPrevUrl() == "live.gizmott.com") {
                    history.push("/home");
                  } else {
                    history.goBack();
                  }
                  // window.location.href = "/home";
                  // history.push("/home")
                }
              }
            });
        } else if (response.status == 202) {
          setotherValues({
            ...otherValues,
            msgErrorLogin: "Please enter a valid user email and password ",
          });
          setFlag({ ...flag, isErrorLoginMsg: true });
          setTimeout(function () {
            setFlag({ ...flag, isErrorLoginMsg: false });
          }, 5000);
        } else if (response.status == 203) {
          // history.push({
          //   pathname: "/register",
          //   state: { facebookData: FBData },
          // });
        } else if (response.status == 201) {
          setotherValues({
            ...otherValues,
            userLoggedId: response.data[0].user_id,
            msgSuccessVerify:
              "OTP sent to your Email (Note:  If you do not find the email in your inbox, please check your spam filter or bulk email folder)",
          });
          setFlag({
            ...flag,
            isLogin: false,
            isVerify: true,
            isSuccessVerifyMsg: true,
          });

          setTimeout(function () {
            setFlag({ ...flag, isSuccessVerifyMsg: false });
          }, 5000);
        } else if (response.status == 204) {
          confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            message: "Do you want to link your Facebook account?",
            buttons: [
              {
                label: "Yes",
                onClick: () => onFBLink(),
              },
              {
                label: "No",
                onClick: () => onFBNoLink(),
              },
            ],
          });
        }
      });
  };
  const onGoogleLink = (profileObj) => {
    service
      .googleLink(profileObj.googleId, profileObj.email)
      .then((response) => {
        let googleData = response.data[0];
        service.userSubscription(response.data[0].user_id).then((response) => {
          setotherValues({ ...otherValues, userLoggedId: googleData.user_id });

          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("userName", googleData.first_name);
          service.setCookie("userId", googleData.user_id, 30);
          localStorage.setItem("userId", googleData.user_id);
          service.setCookie("userEmail", googleData.email, 30);
          service.setCookie("isLoggedIn", "true", 30);

          var user_sub = response.data;
          if (user_sub.length > 0) {
            setotherValues({
              ...otherValues,
              msgSuccessLogin: "You are successfully logged in.",
            });
            setFlag({ ...flag, isSuccessLoginMsg: true });
            setTimeout(function () {
              setFlag({ ...flag, isSuccessLoginMsg: false });
            }, 1000);
            dispatch({ type: "LOGIN", payload: true });
            if (sessionStorage.getItem("tvActivateFlag") == "true") {
              history.push("/tv");
            } else {
              if (getPrevUrl() == "live.gizmott.com") {
                history.push("/home");
              } else {
                history.goBack();
              }
            }
          } else {
            setotherValues({
              ...otherValues,
              msgSuccessLogin: "You are successfully logged in.",
            });
            setFlag({ ...flag, isSuccessLoginMsg: true });
            setTimeout(function () {
              setFlag({ ...flag, isSuccessLoginMsg: false });
            }, 5000);
            dispatch({ type: "LOGIN", payload: true });
            if (sessionStorage.getItem("tvActivateFlag") == "true") {
              history.push("/tv");
            } else {
              if (getPrevUrl() == "live.gizmott.com") {
                history.push("/home");
              } else {
                history.goBack();
              }
            }
          }
        });
      });
  };
  const onGoogleNoLink = () => {
    setFlag({ ...flag, isEmailExistMsg: true });
    setTimeout(function () {
      setFlag({ ...flag, isEmailExistMsg: false });
    }, 5000);
  };

  const onFBLink = () => {
    service
      .facebokLink(
        FBData.id,
        FBData.email,
        FBData.first_name,
        FBData.last_name
      )
      .then((response) => {
        let loginFBData = response.data[0];
        service.userSubscription(response.data[0].user_id).then((response) => {
          setotherValues({ ...otherValues, userLoggedId: loginFBData.user_id });

          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("userName", loginFBData.first_name);
          service.setCookie("userId", loginFBData.user_id, 30);
          localStorage.setItem("userId", loginFBData.user_id);
          service.setCookie("userEmail", FBData.email, 30);
          service.setCookie("isLoggedIn", "true", 30);

          var user_sub = response.data;
          if (user_sub.length > 0) {
            setotherValues({
              ...otherValues,
              msgSuccessLogin: "You are successfully logged in.",
            });
            setFlag({ ...flag, isSuccessLoginMsg: true });
            setTimeout(function () {
              setFlag({ ...flag, isSuccessLoginMsg: false });
            }, 1000);
            dispatch({ type: "LOGIN", payload: true });
            if (sessionStorage.getItem("tvActivateFlag") == "true") {
              history.push("/tv");
            } else {
              if (getPrevUrl() == "live.gizmott.com") {
                history.push("/home");
              } else {
                history.goBack();
              }
            }
          } else {
            setotherValues({
              ...otherValues,
              msgSuccessLogin: "You are successfully logged in.",
            });
            setFlag({ ...flag, isSuccessLoginMsg: true });
            setTimeout(function () {
              setFlag({ ...flag, isSuccessLoginMsg: false });
            }, 5000);
            dispatch({ type: "LOGIN", payload: true });
            if (sessionStorage.getItem("tvActivateFlag") == "true") {
              history.push("/tv");
            } else {
              if (getPrevUrl() == "live.gizmott.com") {
                history.push("/home");
              } else {
                history.goBack();
              }
            }
          }
        });
      });
  };
  const onFBNoLink = () => {
    setFlag({ ...flag, isEmailExistMsg: true });
    setTimeout(function () {
      setFlag({ ...flag, isEmailExistMsg: false });
    }, 5000);
  };
  const onLoginHandler = (e) => {
    e.preventDefault();
    if (validation()) {
      service.login(values).then((response) => {
        console.log("loginData", response);
        if (response.status == 200) {
          let loginData = response.data.data[0];

          if (loginData.screening_user === 1) {
            service.setCookie("isScreeningUser", "true", 30);
          }
          service.userSubscription(loginData.user_id).then((response) => {
            setotherValues({ ...otherValues, userLoggedId: loginData.user_id });
            if (response.forcibleLogout == false) {
              localStorage.setItem("isLoggedIn", true);
              localStorage.setItem("userName", loginData.first_name);
              localStorage.setItem("userId", loginData.user_id);
              service.setCookie("userEmail", loginData.user_email, 30);
              service.setCookie("userId", loginData.user_id, 30);
              service.setCookie("isLoggedIn", "true", 30);

              var user_sub = response.data;
              if (user_sub.length > 0) {
                setotherValues({
                  ...otherValues,
                  msgSuccessLogin: "You are successfully logged in.",
                });
                setFlag({ ...flag, isSuccessLoginMsg: true });

                setTimeout(function () {
                  setFlag({ ...flag, isSuccessLoginMsg: false });
                  if (sessionStorage.getItem("tvActivateFlag") == "true") {
                    history.push("/tv");
                  } else {
                    if (getPrevUrl() == "live.gizmott.com") {
                      history.push("/home");
                    } else {
                      history.goBack();
                    }
                  }
                }, 2000);
                dispatch({ type: "LOGIN", payload: true });
              } else {
                setotherValues({
                  ...otherValues,
                  msgSuccessLogin: "You are successfully logged in.",
                });
                setFlag({ ...flag, isSuccessLoginMsg: true });
                // setTimeout(function () {
                setFlag({ ...flag, isSuccessLoginMsg: false });
                if (sessionStorage.getItem("tvActivateFlag") == "true") {
                  history.push("/tv");
                } else {
                  if (getPrevUrl() == "live.gizmott.com") {
                    history.push("/home");
                  } else {
                    history.goBack();
                  }
                }
                //  }, 2000);
                dispatch({ type: "LOGIN", payload: true });
              }
            } else {
              setFlag({ ...flag, isErrorLogoutMsg: true });
              setTimeout(function () {
                setFlag({ ...flag, isErrorLogoutMsg: false });
              }, 10000);
            }
          });
        } else if (response.status == 202) {
          setotherValues({
            ...otherValues,
            msgErrorLogin: "Please enter a valid user email and password ",
          });
          setFlag({ ...flag, isErrorLoginMsg: true });
          setTimeout(function () {
            setFlag({ ...flag, isErrorLoginMsg: false });
          }, 5000);
        } else if (response.status == 203) {
          setotherValues({
            ...otherValues,
            msgErrorLogin: "Login limit exceed",
          });
          setFlag({ ...flag, isErrorLoginMsg: true });
          setTimeout(function () {
            setFlag({ ...flag, isErrorLoginMsg: false });
          }, 5000);
        } else if (response.status == 201) {
          setotherValues({
            ...otherValues,
            userLoggedId: response.data.data[0].user_id,
            msgSuccessVerify:
              "OTP sent to your Email (Note:  If you do not find the email in your inbox, please check your spam filter or bulk email folder)",
          });
          setFlag({
            ...flag,
            isLogin: false,
            isVerify: true,
            isSuccessVerifyMsg: true,
          });

          setTimeout(function () {
            setFlag({
              ...flag,
              isSuccessVerifyMsg: false,
              isVerify: true,
              isLogin: false,
            });
          }, 5000);
        }
      });
    }
  };
  const onVerifyHandler = (e) => {
    e.preventDefault();
    if (validationVerify()) {
      service
        .verifyEmail(valuesVerify, otherValues.userLoggedId)
        .then((response) => {
          console.log(
            "response of the email varification in sign in page",
            response
          );
          if (response.success == true) {
            localStorage.setItem("isLoggedIn", true);
            service.setCookie("userId", otherValues.userLoggedId, 30);
            service.setCookie("isLoggedIn", "true", 30);

            setotherValues({
              ...otherValues,
              msgSuccessVerify: "Your registration is completed ",
            });
            setFlag({ ...flag, isSuccessVerifyMsg: true });
            setTimeout(function () {
              setFlag({ ...flag, isSuccessVerifyMsg: false });
            }, 5000);
            if (sessionStorage.getItem("tvActivateFlag") == "true") {
              history.push("/tv");
            } else {
              window.location.href = "/";
            }
          } else {
            setotherValues({ ...otherValues, msgErrorVerify: "Invalid OTP" });
            setFlag({ ...flag, isErrorVerifyMsg: true });
            setTimeout(function () {
              setFlag({ ...flag, isErrorVerifyMsg: false });
            }, 5000);
          }
        });
    }
  };
  const onForgotHandler = (e) => {
    e.preventDefault();
    if (validationForgot()) {
      service.forgotEmail(valuesForgot).then((response) => {
        if (response.success == true) {
          setotherValues({
            ...otherValues,
            msgSuccessForgot:
              "Reset Password Link sent to your Email Id (Note:  If you do not find the email in your inbox, please check your spam filter or bulk email folder) ",
          });
          setFlag({ ...flag, isSuccessForgotMsg: true });
          setTimeout(function () {
            setFlag({
              ...flag,
              isSuccessForgotMsg: false,
              isLogin: true,
              isForgot: false,
            });
          }, 5000);
        } else if (response.success == false) {
          setotherValues({
            ...otherValues,
            msgErrorForgot: "Email id does't exist.",
          });
          setFlag({ ...flag, isErrorForgotMsg: true });
          setTimeout(function () {
            setFlag({ ...flag, isErrorForgotMsg: false });
          }, 5000);
        }
      });
    }
  };

  const onClickForgot = () => {
    // document.getElementById("signInLink").style = {
    //   display: "block",
    //   "padding-top": "6px",
    // };

    setFlag({ ...flag, isLogin: false, isForgot: true });

    // setFlag({ ...flag, isForgot: true });
  };

  const onLogout = () => {
    service.logoutAll(otherValues.userLoggedId).then((response) => {
      if (response.success == true) {
        setFlag({ ...flag, isSuccessLogoutMsg: true });
        setTimeout(function () {
          setFlag({ ...flag, isSuccessLogoutMsg: false });
        }, 5000);
      } else {
        setFlag({ ...flag, isErrorLogoutMsg: true });
        setTimeout(function () {
          setFlag({ ...flag, isErrorLogoutMsg: false });
        }, 5000);
      }
    });
  };
  window.signInTrigger = () => {
    setFlag({ ...flag, isLogin: true, isForgot: false });

    // setFlag({ ...flag, isForgot: false });
    document.getElementById("signInLink").style.display = "none";
  };

  return (
    <div className="pageWrapper leftpadd">
      <div className="topContainer">
        <div className="signPage menuCloseJS closeMenuWrapper">
          <div className="container">
            <div
              className="row signWrapper"
              style={{ marginRight: "0", marginLeft: "0" }}
            >
              <div
                className="col col-9 col-lg-6 col-xl-6 col-xxl-4"
                style={{ padding: "0 2px" }}
              >
                <h3 className="H3">Welcome!</h3>
                {/* <h4 style={{color:"red",textAlign:"center",fontSize:"13px", lineHeight:"20px"}}>Signing in through Facebook is going through an update and <br></br> not available at this time</h4> */}
                <div>
                  <div rel="noopener" target="_self">
                    <button
                      className="button buttonLarge buttonBlock registerFacebook"
                      style={{ marginLeft: "0" }}
                    >
                      <div className="buttonBg"></div>
                      <FacebookLogin
                        style={{ marginLeft: "0" }}
                        appId="845860546162071"
                        isMobile={false}
                        fields="name,email,picture,first_name,last_name"
                        callback={responseFacebook}
                        cssClass="button buttonLarge buttonBlock registerFacebook"
                        textButton="Login via Facebook"
                      />
                    </button>
                  </div>
                  <div className="orContainer signOrMargin">
                    <div className="orDivider"></div>
                    <div className="orCircle">
                      <div className="orText">OR</div>
                    </div>
                    <div className="orDivider"></div>
                  </div>
                  {/* { showloginButton ?
                <GoogleLogin

              style={{width:"100px",height:"900px"}}
                    clientId={clientId}
                    buttonText="Sign In"
                    onSuccess={onLoginSuccess}
                    onFailure={onLoginFailure}
                    cookiePolicy={'single_host_origin'}
                    isSignedIn={true}
                /> : null}

            { showlogoutButton ?
                <GoogleLogout
                    clientId={clientId}
                    buttonText="Sign Out"
                    onLogoutSuccess={onSignoutSuccess}
                >
                </GoogleLogout> : null
            } */}
                  {flag.isForgot && (
                    <div id="forgotId">
                      <h5 className="H5 signFormHeading">Forgot Password</h5>
                      <form
                        className="signFormWrapper"
                        noValidate
                        onSubmit={onForgotHandler}
                      >
                        {flag.isSuccessForgotMsg && (
                          <p className="_3nmo_success">
                            {otherValues.msgSuccessForgot}
                          </p>
                        )}
                        {flag.isErrorForgotMsg && (
                          <p className="_3nmo_">{otherValues.msgErrorForgot}</p>
                        )}
                        <div
                          onClick={() =>
                            setFlag({ ...flag, isForgotEmailSelected: true })
                          }
                          onBlur={() =>
                            setFlag({ ...flag, isForgotEmailSelected: false })
                          }
                          className={`input ${otherValues.forgot_email} ${
                            flag.isForgotEmailSelected ? "inputActive" : ""
                          }`}
                        >
                          <input
                            className="inputText"
                            style={{
                              border: "none",
                              padding: "0px",
                              marginTop: "10px",
                            }}
                            name="forgot_email"
                            type="email"
                            value={valuesForgot.forgot_email}
                            onChange={onChangeHandlerForgot}
                          />
                          <span className="inputLabel">
                            {errorsForgot.forgot_email}
                          </span>
                        </div>
                        <div className="row signSubmitWrapper">
                          <div className="col col-sm-6 col-sm-offset-6">
                            <button
                              className="button buttonLarge buttonBlock"
                              type="submit"
                            >
                              <div
                                className="buttonBg"
                                //   style={{ backgroundColor: "#fbcd37" }}
                              ></div>
                              <div className="buttonContent">Submit</div>
                            </button>
                          </div>
                        </div>
                        <div className="signAgree">
                          <p>
                            {" "}
                            <span>
                              Already have an account?{" "}
                              <span
                                className="linkButton"
                                onClick={() => {
                                  setFlag({
                                    ...flag,
                                    isLogin: true,
                                    isForgot: false,
                                  });
                                  // setFlag({ ...flag, isForgot: false });
                                  if (document.getElementById("signInLink")) {
                                    document.getElementById(
                                      "signInLink"
                                    ).style.display = "none";
                                  }
                                }}
                              >
                                Sign In
                              </span>
                            </span>
                            <span>·</span> Don't have an account?{" "}
                            <Link className="linkButton" to="/register">
                              <span
                                style={{
                                  fontWeight: "700",
                                  letterSpacing: "0.5px",
                                  verticalAlign: "initial",
                                }}
                              >
                                {" "}
                                Registration is free.
                              </span>
                            </Link>
                          </p>
                        </div>
                      </form>
                    </div>
                  )}

                  {flag.isVerify && (
                    <div id="verifyId">
                      <h5 className="H5 signFormHeading">Email Verification</h5>
                      <form
                        className="signFormWrapper"
                        noValidate
                        onSubmit={onVerifyHandler}
                        autocomplete="off"
                      >
                        {flag.isSuccessVerifyMsg && (
                          <p className="_3nmo_success">
                            {otherValues.msgSuccessVerify}
                          </p>
                        )}
                        {flag.isErrorVerifyMsg && (
                          <p className="_3nmo_">{otherValues.msgErrorVerify}</p>
                        )}
                        <div
                          onClick={() =>
                            setFlag({ ...flag, isVerifyEmailSelected: true })
                          }
                          onBlur={() =>
                            setFlag({ ...flag, isVerifyEmailSelected: false })
                          }
                          className={`input ${otherValues.verification_code} ${
                            flag.isVerifyEmailSelected ? "inputActive" : ""
                          }`}
                        >
                          <input
                            className="inputText"
                            style={{
                              border: "none",
                              padding: "0px",
                              marginTop: "10px",
                            }}
                            name="verification_code"
                            type="text"
                            maxLength="60"
                            value={values.verification_code}
                            onChange={onChangeHandlerVerify}
                          />
                          <span className="inputLabel">
                            {errorsVerify.verification_code}
                          </span>
                        </div>

                        <div className="row signSubmitWrapper">
                          <div className="col col-sm-6 col-sm-offset-6">
                            <button
                              className="button buttonLarge buttonBlock"
                              type="submit"
                            >
                              <div
                                className="buttonBg"
                                //   style={{ backgroundColor: "#fbcd37" }}
                              ></div>
                              <div className="buttonContent">Verify</div>
                            </button>
                          </div>
                        </div>
                        <div className="signAgree">
                          <p>
                            <span>
                              Already have an account?{" "}
                              <a className="linkButton" href="/signin">
                                Sign In
                              </a>
                            </span>{" "}
                            <span>·</span> Don't have an account?{" "}
                            <Link className="linkButton" to="/register">
                              <span
                                style={{
                                  fontWeight: "700",
                                  letterSpacing: "0.5px",
                                }}
                              >
                                {" "}
                                Registration is free.
                              </span>
                            </Link>
                          </p>
                        </div>
                      </form>
                    </div>
                  )}

                  {flag.isLogin && (
                    <div id="loginId">
                      <h5 className="H5 signFormHeading">Sign In via Email</h5>

                      <form
                        className="signFormWrapper"
                        noValidate
                        onSubmit={onLoginHandler}
                      >
                        {flag.isSuccessLoginMsg && (
                          <p className="_3nmo_success">
                            {otherValues.msgSuccessLogin}
                          </p>
                        )}
                        {flag.isErrorLoginMsg && (
                          <p className="_3nmo_">{otherValues.msgErrorLogin}</p>
                        )}
                        {flag.isSuccessLogoutMsg && (
                          <p className="_3nmo_success">
                            You are successfully logout from all devices
                          </p>
                        )}
                        {flag.isErrorLogoutMsg && (
                          <p className="_3nmo_">
                            Login limit exceed&nbsp;&nbsp;
                            <button
                              id="logoutBtn"
                              onClick={onLogout}
                              className="linkButton button buttonSmall"
                            >
                              <div
                                className="buttonBg"
                                //   style={{ backgroundColor: "#fbcd37" }}
                              ></div>
                              <div className="buttonContent">Logout All</div>
                            </button>
                          </p>
                        )}
                        {flag.isErrorLogout && (
                          <p className="_3nmo_">
                            Something went wrong. Please Try Again
                          </p>
                        )}
                        {flag.isEmailExistMsg && (
                          <p className="_3nmo_">
                            {facebookEmail} already exist, Please sign
                            in&nbsp;&nbsp;
                          </p>
                        )}

                        <div
                          onClick={() =>
                            setFlag({ ...flag, isEmailSelected: true })
                          }
                          onBlur={() =>
                            setFlag({ ...flag, isEmailSelected: true })
                          }
                          className={`input ${otherValues.email} ${
                            flag.isEmailSelected ? "inputActive" : ""
                          }`}
                        >
                          <input
                            className="inputText"
                            style={{
                              border: "none",
                              padding: "0px",
                              marginTop: "10px",
                            }}
                            name="email"
                            type="email"
                            value={values.email}
                            onChange={onChangeHandler}
                          />
                          <span className="inputLabel">{errors.email}</span>
                          {!values.email && (
                            <span className="inputHint mb__inputHint">
                              We never share this
                            </span>
                          )}
                        </div>
                        <div
                          onClick={() =>
                            setFlag({ ...flag, isPasswordSelected: true })
                          }
                          onBlur={() =>
                            setFlag({ ...flag, isPasswordSelected: false })
                          }
                          className={`input ${otherValues.password} ${
                            flag.isPasswordSelected ? "inputActive" : ""
                          }`}
                        >
                          <input
                            className="inputText"
                            title="Length must be between 6 and 30"
                            style={{
                              border: "none",
                              padding: "0px",
                              marginTop: "10px",
                            }}
                            name="password"
                            type={passwordShown1 ? "text" : "password"}
                            value={values.password}
                            onChange={onChangeHandler}
                          />
                          {isEye1 && (
                            <i
                              className="eyeIcon"
                              onClick={togglePasswordVisiblity1}
                            >
                              {eye1}
                            </i>
                          )}
                          <span className="inputLabel">{errors.password}</span>
                          {!values.password && (
                            <span className="inputHint mb__inputHint">
                              Enter your password
                            </span>
                          )}
                        </div>
                        <div className="regnSubmitWrapper">
                          <p
                            style={{
                              paddingTop: "10px",
                              fontSize: "14px",
                              color: "rgb(112, 124, 134)",
                            }}
                          >
                            {" "}
                            Don't have an account?
                            <Link to={{ pathname: "/register" }}>
                              <span
                                className="linkButton"
                                style={{
                                  fontWeight: "700",
                                  letterSpacing: "0.5px",
                                }}
                              >
                                &nbsp; Registration is free.
                              </span>
                            </Link>
                          </p>
                          <button
                            className="button buttonLarge regnSubmit"
                            type="submit"
                          >
                            <div
                              className="buttonBg"
                              // style={{ backgroundColor: "#fbcd37" }}
                            ></div>
                            <div className="buttonContent">Sign In</div>
                          </button>
                        </div>
                        <div className="signAgree">
                          <p>
                            <span
                              className="linkButton"
                              onClick={onClickForgot}
                            >
                              Forgot password?
                            </span>
                          </p>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
